import './navbar.css'

export default function Navbar() {
    return (
        <div className='navbar'>
            <div className='right'>
                <a className='nav-item' href='/'>Home</a>
                <a className='nav-item' href='/#projects'>Projects</a>
                <a className='nav-item' href='/#footer'>Connect</a>
            </div>
        </div>
    )
}