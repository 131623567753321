import './App.css';
import Navbar from './navbar';
import Footer from './footer';
import logo from './Logo .png';
import printt from './Hamilton Print.png'
import drawing from './Drawing Final.png'
import { useState } from 'react';


export default function Home() {

  const [filter, setFilter] = useState("all");

  const projects = [
    {
      title: "Martin Garrix E-Commerce Store",
      tech: "React + CSS + JavaScript",
      img: "https://wesleyrobertson.com/static/media/Garrix.b1687bad65a3e8f402ae.png",
      description: "A concept e-commerce platform designed for fans of Martin Garrix to explore and shop official merchandise. Built with a front-end focus using React and CSS, the project emphasizes clean UI, smooth navigation, and responsive design. It serves as a showcase of how a modern artist storefront can look and feel.",
      live: "https://sparkling-kitsune-6d6b5b.netlify.app/",
      github: "https://github.com/wesgolf/Martin-Garrix-Ecommerce-Website",
      type: 1,
      filterr: "web"
    },
    {
      title: "CourseIQ",
      tech: "React + Firebase + CSS",
      img: "https://preview.redd.it/aaq3s78f9yl41.png?width=640&crop=smart&auto=webp&s=cd41f85db8dad883dabba73d3468ea6d61c781b9",
      description: "A golf stat tracking web app that enables users to analyze gameplay using advanced metrics. Built with React and Firebase, CourseIQ provides a clean UI for viewing round summaries, club performance, and personalized insights to help players improve.",
      live: "https://github.com/wesgolf/courseiq",
      type: 2,
      saying: "GitHub",
      filterr : "web"
    },
    {
      title: "OnlyFriends",
      tech: "React + Firebase + APIs",
      img: "https://github.com/b4sleal/OnlyFriends/raw/main/examples/example1.gif", // Add an image if available
      description: "A social networking app that connects university students by program and school. Created to help students find academic support, study buddies, and friends in their major. Built with Firebase for authentication and React on the frontend.",
      live: "https://github.com/b4sleal/OnlyFriends",
      type: 2,
      saying: "GitHub",
      filterr : "web"
    },
    {
      title: "Wes Music",
      tech: "Logic Pro + Final Cut + Branding",
      img: logo,
      description: "A personal music brand launched during the COVID lockdown. Wes Music has earned over 200K+ streams, signed with multiple labels, and performed live across Ontario. The project includes music production, branding, video editing, and promotion.",
      live: "https://soundcloud.com/wesmusic1",
      github: "",
      type: 2,
      saying: "Soundcloud",
      filterr : "graphic"
    },
    {
      title: "Golf Course Prints",
      tech: "Affinity Designer + Entrepreneurship",
      img: printt,
      description: "A business venture focused on creating and selling custom-designed golf course prints. The project blends graphic design, golf knowledge, and entrepreneurship — resulting in products sold at pro shops and charity events. Each print is designed with precision using Affinity Designer, turning course layouts into premium wall art.",
      github: "",
      type: 3,
      filterr : "graphic"
    },
    {
      title: "Connect+",
      tech: "Adobe XD + UX/UI",
      img: "https://wesleyrobertson.com/static/media/Artboard%201.6014a0df487881150166.png",
      description: "A mental health support app designed in Adobe XD to facilitate seamless peer connection and emotional support. The interface focuses on calm, intuitive navigation and personalized resources to empower users on their mental health journey.",
      type: 3,
      filterr : "graphic"
    },
    {
      title: "Golf Course Design",
      tech: "Golf Course Architecture + CAD",
      img: drawing, // Add your course layout preview later
      description: "A personal design challenge focused on creating a full 18-hole golf course layout from scratch. This project applies real-world golf architecture principles such as routing, shot variety, and playability. Currently in progress with plans to render full 2D and 3D visuals.",
      live: "/Drawing Final.png",
      github: "",
      type: 2,
      saying: "Preview",
      filterr : "graphic"
    }

  ];

  const filteredProjects = projects.filter((project) => {
    if (filter === "all") return true;
    if (filter === "web") return project.filterr === "web";
    if (filter === "design") return project.filterr === "graphic";
    return true;
  });  


  return (
    <div className='background'>
      <Navbar />
      <div className='hero'>
        <h2 className=''>Hi! My name is</h2>
        <h1>Wesley Robertson</h1>
        <p className="subheading">I'm a developer, designer, and DJ — building cool things and making them look good.</p>
        <a className="scroll-indicator" href='#projects'>↓</a>
      </div>
      <div className='projects' id='projects'>
        <h4>Projects</h4>
        <div className='filter'>
          <p style={{ margin: "0px", marginRight: "10px", cursor: "Default" }} className="bold">Filter By:</p>
          <p onClick={() => setFilter("all")} className={filter === "all" ? "bold underline" : ""}>All</p>
          <p onClick={() => setFilter("web")} className={filter === "web" ? "bold underline" : ""}>Web Development</p>
          <p onClick={() => setFilter("design")} className={filter === "design" ? "bold underline" : ""}>Graphic Design</p>
        </div>
        <div className="projects-grid">
          {filteredProjects.map((project, index) => (
            <div key={index} className="project-card">
              <img src={project.img} />
              <h3>{project.title}</h3>
              <p className="tech-stack">{project.tech}</p>
              <p className='desc'>{project.description}</p>
              <div className={project.type === 2 ? 'single-link' : project.type === 3 ? 'no-links' : 'project-links'}>
                {project.type === 2 ? (
                  <div>
                    <a href={project.live} target="_blank" rel="noreferrer">
                      {project.saying}
                    </a>
                  </div>
                ) : (
                  <>
                    {project.live && (
                      <div style={{ borderRight: project.github ? '1px solid black' : 'none' }}>
                        <a href={project.live} target="_blank" rel="noreferrer">Live Demo</a>
                      </div>
                    )}
                    {project.github && (
                      <div>
                        <a href={project.github} target="_blank" rel="noreferrer">GitHub</a>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

