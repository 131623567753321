import './footer.css';

export default function Footer() {
    return (
        <div className="footer-container" id='footer'>
            <div className='footer-1'>
                <div>
                    <h5>More About Me</h5>
                    <p><a href="/Resume.pdf" target="_blank" rel="noreferrer">Resume</a></p>
                    <p><a href="https://www.linkedin.com/in/wesleyrobertson1/?originalSubdomain=ca" target="_blank" rel="noreferrer">LinkedIn</a></p>
                    <p><a href="https://github.com/wesgolf" target="_blank" rel="noreferrer">GitHub</a></p>
                </div>

                <div>
                    <h5>Contact</h5>
                    <p><a href="mailto:robe4390@mylaurier.ca">robe4390@mylaurier.ca</a></p>
                    <p>Ancaster, Ontario</p>
                </div>

                <div>
                    <h5>Education</h5>
                    <p>🎓 Computational Math @ UW</p>
                    <p>📈 Marketing @ Laurier</p>
                    <p>🎯 Graduation: May 2025</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Built with 💻, ☕, and way too many late nights. © 2025 Wesley Robertson</p>
            </div>
        </div>
    );
}
